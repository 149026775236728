import React, { useState, useEffect, useRef } from 'react'
import FormHeader from '../../components/FormHeader'
import FormFooter from '../../components/FormFooter'
import {
  Row,
  Col,
  Container,
  Card,
  ButtonGroup,
  ToggleButton,
  Alert,
} from 'react-bootstrap'
import { Bag, InfoCircleFill } from 'react-bootstrap-icons'
import lang from '../../assets/loadLanguage'
import Select from 'react-select'

export default function Product({
  formValues,
  handlePrevStep,
  handleNextStep,
  activeStep,
  stepCount,
  makeRequest,
  editing = false,
}) {
  const [productid, setProductid] = useState(formValues.productid)
  const [courier, setCourier] = useState(formValues.courier)
  const [foxpostMachine, setFoxpostMachine] = useState(
    formValues.foxpostMachine
  )
  const [foxpostError, setFoxpostError] = useState('')
  const [foxpostList, setFoxpostList] = useState([])
  const foxpostRef = useRef(null)

  function handleNext() {
    if (courier === '24H')
      handleNextStep({ productid, courier, foxpostMachine })
    else {
      setFoxpostError('')
      if (foxpostMachine.value)
        handleNextStep({ productid, courier, foxpostMachine })
      else {
        setFoxpostError(lang.missingFoxpostInput)
        foxpostRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  // Fetching Foxpost machine list from FoxpostCDN
  useEffect(() => {
    const foxpostMachines = []
    fetch('./foxpostMachines.json', {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        data.forEach((item) =>
          foxpostMachines.push({
            label: `${item.name} - ${item.address}`,
            value: item.operator_id,
          })
        )
        foxpostMachines.sort((a, b) => a.label.localeCompare(b.label))
        setFoxpostList(foxpostMachines)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])
  return (
    <>
      <FormHeader
        handleNextStep={handleNext}
        handlePrevStep={() =>
          handlePrevStep({ productid, courier, foxpostMachine })
        }
        activeStep={activeStep}
        stepCount={stepCount}
        editing={editing}
      />
      <Row>
        <Container>
          <Col />
          <Col sm={12} md={10} lg={8} xl={6} className="m-auto">
            <Card className="py-4 px-3 mt-4 shadow rounded text-center">
              <Row>
                <Bag size={40} />
              </Row>
              <Row className="mt-3">
                <h4>{lang.productsTitle}</h4>
                <p>{lang.productDescription}</p>
                <p>{lang.couponInfo}</p>
              </Row>
              <Row className="mt-2">
                <ButtonGroup className="mb-2 w-100">
                  <Container className="p-1">
                    <Row>
                      <Col xs={6} className="p-1">
                        <ToggleButton
                          className="w-100 p-1 unselected product"
                          id="radio-productid-5"
                          type="radio"
                          variant={productid === '5' ? 'primary' : 'light'}
                          name="productid"
                          value={'5'}
                          onChange={() => {
                            setProductid('5')
                            setCourier('Foxpost')
                          }}
                        >
                          <span className="fs-4 d-block">
                            {lang.onePackLabel}
                          </span>
                          <span className="fs-4 d-block">
                            {lang.onePackPrice}
                          </span>
                          <span className="d-block">{lang.onePackLength}</span>
                        </ToggleButton>
                      </Col>
                      <Col xs={6} className="p-1">
                        <ToggleButton
                          className="w-100 pt-1 unselected product"
                          id="radio-productid-3"
                          type="radio"
                          variant={productid === '3' ? 'primary' : 'light'}
                          name="productid"
                          value={'3'}
                          onChange={() => setProductid('3')}
                        >
                          <span className="fs-4 d-block">
                            {lang.threePackLabel}
                          </span>
                          <span className="fs-4 d-block">
                            {lang.threePackPrice}
                          </span>
                          <span className="d-block">
                            {lang.threePackLength}
                          </span>
                        </ToggleButton>
                      </Col>
                    </Row>
                  </Container>
                </ButtonGroup>
              </Row>
              <Row className="mt-2 mb-1">
                <h4>{lang.deliveryLabel}</h4>
              </Row>
              <Alert variant="info">
                <InfoCircleFill className="me-2" />
                {lang.onlyFoxpostInfo}
              </Alert>
              <Row>
                <ButtonGroup className="mb-2 w-100">
                  <Container>
                    <Row>
                      <Col xs={12} sm={6}>
                        <ToggleButton
                          className="w-100 unselected"
                          id="radio-courier-24H"
                          type="radio"
                          variant={courier === '24H' ? 'primary' : 'light'}
                          name="courier"
                          value={'24H'}
                          onChange={() => setCourier('24H')}
                          disabled
                        >
                          {lang.homeDelivLabel}
                        </ToggleButton>
                      </Col>
                      <Col xs={12} sm={6}>
                        <ToggleButton
                          className="w-100 unselected"
                          id="radio-courier-Foxpost"
                          type="radio"
                          variant={courier === 'Foxpost' ? 'primary' : 'light'}
                          name="courier"
                          value={'Foxpost'}
                          onChange={() => setCourier('Foxpost')}
                        >
                          {lang.foxpostLabel}
                        </ToggleButton>
                      </Col>
                    </Row>
                  </Container>
                </ButtonGroup>
              </Row>
              {courier === 'Foxpost' ? (
                <Row className="mt-3" ref={foxpostRef}>
                  <Select
                    options={foxpostList}
                    onChange={(value) => setFoxpostMachine(value)}
                    search
                    placeholder={lang.foxpostPlaceholder}
                    name="foxpostMachine"
                    value={foxpostMachine}
                    menuPlacement="auto"
                    minMenuHeight="400px"
                    menuShouldScrollIntoView={true}
                  />
                  <div className="invalid-feedback d-block">{foxpostError}</div>
                </Row>
              ) : (
                <Alert variant="info" className="m-1 mt-3">
                  <InfoCircleFill className="me-2" />
                  {lang.courierInfo}
                </Alert>
              )}
            </Card>
          </Col>
          <Col />
        </Container>
      </Row>
      <FormFooter
        handleNextStep={handleNext}
        handlePrevStep={() =>
          handlePrevStep({ productid, courier, foxpostMachine })
        }
        activeStep={activeStep}
        stepCount={stepCount}
        makeRequest={makeRequest}
        editing={editing}
      />
    </>
  )
}
