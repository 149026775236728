const lang = {
  playerCountOptionLabel2: 'Ketten',
  playerCountOptionLabel3: 'Hárman',
  playerCountOptionLabel4: 'Négyen',
  playerCountOptionLabel5: 'Öten',
  playerCountOptionLabel6: 'Hatan, vagy többen',
  difficultyLabel1: 'Legkönnyebb (például Dobble)',
  difficultyLabel2: 'Könnyű (például Kingdomino)',
  difficultyLabel3: 'Közepes (például Stone Age)',
  difficultyLabel4: 'Komplex (pl. Mars Terraformálása)',
  playTimeOptionLabel1: 'Rövidebb, mint egy óra',
  playTimeOptionLabel2: '1-2 óra',
  playTimeOptionLabel3: '2-3 óra',
  playTimeOptionLabel4: '3 óránál hosszabb',
  signInError: 'A bejelentkezés nem sikerült',
  signInTitle: 'Játszó/Házhoz bejelentkezés',
  emailLabel: 'Email cím',
  passwordLabel: 'Jelszó',
  passwordConfirmLabel: 'Jelszó megerősítés',
  loginButtonText: 'Bejelentkezés',
  goToLoginButtonText: 'Tovább a bejelentkezésre',
  forgottenPasswordText: 'Elfelejtett jelszó',
  notRegisteredYetText: 'Még nem regisztráltál?',
  registerButtonText: 'Regisztráció',
  registerTitle: 'Játszó/Házhoz regisztráció',
  invalidLinkText:
    'Ez a link már lejárt. Kérj egy új jelszó-emlékeztető emailt.',
  passwordsDontMatchText: 'Nem egyezik meg a két jelszó.',
  passwordHasBeenUpdatedText: 'A jelszavad megváltozott.',
  newPasswordLabel: 'Új jelszó',
  newPasswordConfirmLabel: 'Új jelszó még egyszer',
  registrationDone:
    'Köszönjük a regisztrációt! Most átírányítunk a bejelentkezésre...',
  saveButtonText: 'Mentés',
  sendButtonText: 'Küldés',
  orderButtonText: 'Rendelés',
  homeButtonText: 'Kezdőlap',
  settingsButtonText: 'Előfizetésem',
  shopButtonText: 'Vásárlás',
  logoutButtonText: 'Kijelentkezés',
  settingsTitleText: 'Előfizetésem',
  resetPasswordDone: 'A további lépéseket elküldtük a megadott email címre.',
  genericError: 'Hoppá, valami elromlott. :(',
  forgottenPasswordTitle: 'Elfelejtett jelszó',
  newPasswordButton: 'Új jelszót kérek',
  shopTitle: 'Vásárlás',
  foxpostPlaceholder: 'Keresés az automaták között...',
  missingValueText: 'Hiányzó érték',
  invalidEmail: 'Hibás email cím',
  invalidPasswordLength: 'A jelszó legalább 5 karakter hosszú kell hogy legyen',
  invalidPasswordFormat:
    'A jelszónak tartalmaznia kell legalább egy nagy és legalább egy kis betűt',
  invalidFormat: 'Hibás formátum',
  missingFoxpostInput: 'Nincs kiválasztva automata.',
  addressTitle: 'Szállítási és számlázási információk',
  lastNameLabel: 'Vezetéknév',
  firstNameLabel: 'Keresztnév',
  phoneLabel: 'Telefonszám',
  phonePrefix: '+36',
  postcodeLabel: 'Irányítószám',
  cityLabel: 'Város',
  addr1Label: 'Utca, házszám',
  addr2Label: 'Emelet, ajtó, egyéb (opcionális)',
  addrNoteLabel: 'Megjegyzés a futárnak (opcionális)',
  summaryTitle: 'Összegzés',
  preferencesTitle: 'Preferenciák',
  playerCountLabel: 'Játékosszám',
  difficultyLabel: 'Komplexitás',
  playTimeLabel: 'Játékidő',
  childrenLabel: 'Gyerekjátékok',
  childrenTypeLabel1: 'Minden csomagban kérek egyet',
  childrenTypeLabel2: 'Csak gyerekjátékokat kérek',
  childrenAgeLabel1: '1-2. osztályos gyerekeknek',
  childrenAgeLabel2: '3-4. osztályos gyerekeknek',
  noLabel: 'Nem',
  yesLabel: 'Igen',
  nvmLabel: 'Mindegy',
  twoPlayerLabel: 'Csak ketten is jó',
  langPrefLabel: 'Csak magyar vagy nyelvfüggetlen',
  coopLabel: 'Kooperatív',
  favouritesLabel: 'Kedvencek',
  notesLabel: 'Egyéb üzenet',
  wishlistLabel: 'Kért játékok',
  unwantedLabel: 'Nem kért játékok',
  productsTitleShort: 'Előfizetés',
  deliveryLabel: 'Szállítási mód',
  foxpostLabel: 'Foxpost csomagautomata',
  homeDelivLabel: 'Házhozszállítás',
  loginInfoTitle: 'Bejelentkezési adatok',
  noteTitle: 'Bármilyen egyéb üzenet a játékokkal kapcsolatban',
  playerCountTitle: 'Általában hányan fogtok játszani?',
  difficultyTitle: 'Elsősorban milyen komplexitású játékokat szeretnél kapni?',
  playTimeTitle: 'Elsősorban milyen hosszú játékokat szeretnél kapni?',
  childrenTitle: 'Szeretnél gyerekjátékokat is kapni?',
  childrenHelpText:
    '5. osztálytól felfelé már inkább a könnyedebb felnőtt játékokat ajánljuk.',
  twoPlayerTitle: 'Csak olyan játékokat szeretnél, amik ketten is jók?',
  langPrefTitle: 'Csak magyar nyelvű játékokat szeretnél?',
  langPrefDesc:
    'Nem magyar kiadású, de teljesen nyelvfüggetlen játékok mindenképpen előfordulhatnak a csomagodban.',
  coopTitle: 'Szeretnél kooperatív játékokat?',
  favouritesTitle: 'Sorolj fel néhányat a kedvenc játékaid közül!',
  productsTitle: 'Hány csomagra szeretnél előfizetni?',
  foxpostLinkText: 'A Foxpost oldalán elérhető térképes kereső (külön ablakban nyílik meg)',
  unwantedTitle: 'Van olyan játék, amit NEM szeretnél kapni a csomagodban?',
  unwantedHelpText:
    'Például mert megvan otthon, vagy esetleg már próbáltad és nem annyira tetszett.',
  searchPlaceholder: 'Keresés...',
  wishlistTitle: 'Melyik játékokat próbálnád ki szívesen?',
  wishlistHelpText1: 'Nem ismersz egyet sem?',
  wishlistHelpText2:
    'Semmi gond. Nyugodtan hagyd ki ezt a lépést és mi gondoskodunk róla, hogy olyan játékokat kapj, amik tetszeni fognak.',
  wishlistHelpText3: 'Pontosan tudod, hogy mit szeretnél?',
  wishlistHelpText4:
    'Válaszd ki a vágyott játékokat fontossági sorrendben! Igyekszünk minden kérésnek eleget tenni, de nem garantált, hogy az itt kiválasztott játékokat fogod kapni.',
  wishlistHelpText5: 'Fontos',
  wishlistHelpText6:
    'Ez a lista az összes játékunkat tartalmazza és nincs szűrve a korábban megadott preferenciák alapján. Ha itt kiválasztasz egy játékot, akkor azt mindenképpen megpróbáljuk beletenni valamelyik csomagodba és nem vizsgáljuk, hogy illeszkedik-e a megadott preferenciákba. A zöld videó ikon jelzi, hogy az adott játékhoz elérhető-e Játszóházas szabálymagyarázó videó.',
  wishlistHelpText7:
    'Ha esetleg szeretnél újra kölcsönkapni egy olyan játékot, ami egy korábbi csomagodban már benne volt, akkor ezt külön jelezd a "Preferenciák/Egyéb üzenet"-nél.',
  submitButtonText: 'Küldés',
  doneButtonText: 'Kész',
  preferencesHelpText:
    'Üdv a Játszó/Házhoz regisztrációs űrlapon! Az első néhány kérdés segít nekünk meghatározni, hogy milyen típusú játékok fognak tetszeni neked. Nyugodtan kihagyhatod bármelyik kérdést, ha nem tudsz, vagy nem szeretnél válaszolni rá, preferenciáidat később is módosíthatod majd.',
  multiChoiceHelpText: 'Többet is megjelölhetsz.',
  sourceLabel: 'Honnan hallottál rólunk?',
  newsletterLabel: 'Szeretnék feliratkozni a Játszóház Projekt hírlevélre',
  termsLinkLabel:
    'itt elérhető ÁSZF-et és Adatkezelési tájékoztatót',
  acceptLabel:
    'Elfogadom az ',
  mustAcceptTerms: 'A továbblépéshez kötelező',
  couponLabel: 'Kuponkód',
  updateFailedAlert: 'Sajnos nem sikerült a mentés, kérjük próbáld meg újra!',
  priceTag: ',- Ft',
  priceText: 'Ár',
  addToCartText: 'Kosárba',
  cartTitleText: 'Kosár',
  cartText:
    'A Rendelés gombra kattintva kapsz tőlünk emailben egy díjbekérőt, és ha ezt kifizeted, a következő csomagodban már küldjük is a játékokat.',
  cartNote:
    'Fontos: A következő csomagodba akkor tudjuk betenni a rendelésedet, ha legalább 5 munkanappal a várható érkezés előtt kifizeted azt.',
  sumText: 'Összesen',
  outOfStockText: 'Sajnos ez a termék már elfogyott',
  currentPackTitle: 'Aktuális csomag',
  previousGamesTitle: 'Korábban kapott játékok',
  ratingLabel: 'Hogy tetszett?',
  keepItButtonText: 'Megtartom',
  reportButtonText: 'Valami hiányzik',
  cancelButtonText: 'Mégse',
  arrivedButtonText: 'Átvettem',
  idLabelText: 'Azonosító',
  keepItModalTitle: 'Használt társasjáték vásárlás',
  keepItModalText1:
    "A 'Vásárlás' gombra kattintva kijelented, hogy megvásárolod az alábbi, jelenleg kölcsönzött társasjátékot, így ezt nem kell visszaküldened nekünk. A vásárlás előtt mindenképpen ellenőrizd a játék állapotát és komponenseit!",
  paymentDescription:
    'Fizetés: Emailben kapsz tőlünk egy díjbekérőt, amit a rajta szereplő határidőn belül kiegyenlíthetsz utalással vagy Barionon keresztül kártyás fizetéssel. A fizetés beérkezése után 24 órán belül, email-ben küldjük a számlát.',
  reportGameModalTitle: 'Valami nem stimmel a játékkal?',
  videoRequiredTitle:
    'Csak olyan játékokat szeretnél, amikhez már elérhető Játszóházas szabálymagyarázó videó?',
  videoRequiredLabel: 'Videó szükséges:',
  compensationQuestion:
    'A hiány vagy sérülés számottevően befolyásolja a játékmenetet?',
  compensationDescription:
    'Ha igen, és ezt a csomag átvételétől számított 24 órán belül jelzed nekünk, akkor kárpótlásul a következő csomagodban kettővel több játékot küldünk.',
  settingsDescription:
    'Itt módosíthatod a regisztráció során megadott adatokat és preferenciákat.',
  settingsInfo:
    'A beállítások nem módosíthatók a következő csomagod várható érkezése előtti negyedik naptól a várható érkezés utáni napig.',
  gameStatusSold: 'Ezt a játékot már kifizetted, így nem kell visszaküldened.',
  gameStatusSoldPending: 'Emailben elküldtük a díjbekérőt.',
  orderConfirmMessage:
    'Emailben elküldtük a díjbekérőt, amit utalással vagy Barionon keresztül kártyás fizetéssel tudsz kiegyeníteni. Kérjük, hogy lehetőség szerint ezt minél hamarabb tedd meg! A következő csomagodba csak akkor tudjuk betenni a rendelt játékokat, ha legalább a csomag várható érkezése előtt 5 munkanappal megérkezik hozzánk a befizetés.',
  inactiveSubscription:
    'Előfizetésed még nem aktív. Ha már több mint 24 órája befizetted a díjbekérőt, és még mindig ezt az üzenetet látod, akkor kérjük, hogy mindenképpen írj nekünk egy emailt a jatszohazhoz@gmail.com címre!',
  hi: 'Szia',
  nextPackDate: 'A következő várható szállítási napod',
  okText: 'Rendben',
  signupDoneText:
    'Elküldtük emailben a díjbekérőt a fizetési információkkal. Ha nem érkezik meg néhány percen belül (figyelgesd a spam mappát is!), mindenképpen írj nekünk! Az érkező csomaggal kapcsolatban a Játszó/Házhoz fiókodban találsz majd információkat:',
  signupDoneTitle: 'Köszönjük a regisztrációt!',
  timeoutText: 'Kérjük jelentkezz be újra!',
  packDeliveredText:
    'Itt találod majd az aktuális csomagodban lévő játékokhoz kapcsolódó lehetőségeket, miután átvetted a csomagot.',
  unwantedCountLimit: 'Ennél több játékot itt nem választhatsz ki.',
  productDescription:
    'Egy csomagban négy játék van és 8 hétig lehet nálad. Ha több csomagra fizetsz elő, akkor a csomagok folytatólagosan, egymás után érkeznek. A korábbit mindig csak akkor kell visszaküldened, amikor az újat átveszed.',
  couponInfo:
    'Ha "C" előfizetésre vonatkozó kupont szeretnél beváltani, válaszd a 3 csomag opciót.',
  onePackLabel: '1 csomag',
  onePackPrice: '8.000 Ft',
  onePackWeeklyPrice: '1.000 Ft/hét',
  onePackPriceDiscount: '7.500 Ft',
  threePackLabel: '3 csomag',
  threePackPrice: '21.000 Ft',
  threePackWeeklyPrice: '875 Ft/hét',
  threePackPriceDiscount: '20.000 Ft',
  onePackLength: '8 hét',
  threePackLength: '3 x 8 hét',
  emailInUse: 'Ez az email cím már regisztrálva van.',
  emailNotRegistered: 'Ez az email cím nincs regisztrálva.',
  packageCountText1: 'Még',
  packageCountText2: 'db csomagod van kifizetve.',
  returningUserWelcomeText: 'Előfizetésed jelenleg nem aktív. Ha szeretnél újra játékokat kapni, az Előfizetésem menüpontban új regisztráció nélkül rendelhetsz további csomagokat.',
  addPackDescription:
    'Amíg van aktív előfizetésed, kedvezményes áron hosszabbíthatod meg azt további csomagokkal.',
    addPackDescThreePack:
    '+ 3 csomag, 3x8 hét - ',
    addPackDescOnePack:
    '+ 1 csomag, 8 hétre - ',
  featuresWarning:
    'Közeledik a következő szállításod várható időpontja. A plusz csomagok és új játékok rendelése, illetve a beállítások módosítása funkciók a szállítás időpontja előtt 3 napig, utána egy napig nem lesznek elérhetők.',
  featuresDisabledText: 'Ez a funkció átmenetileg nem elérhető.',
  addPackTitle: 'Előfizetés hosszabbítás',
  newPackTitle: 'Új előfizetés',
  foxpostInfo: 'Csomagodat az automatába érkezéstől számított három napig veheted át.',
  courierInfo: 'A házhozszállítást a 24H futárszolgálat végzi. A futár előre meghatározott (de nem garantált) napon, munkaidőben érkezik, az előzetes értesítés nem tartalmaz időablakot. Ha csak bizonyos időpontokban tudnád átvenni a csomagot, érdemes inkább a csomagautomatás kézbesítést választani.',
  onePackCourierInfo: 'Egy csomagra csak a Foxpost automatás szállítási mód érhető el.',  
  addPackConfirmMessage:
    'Nagyon örülünk, hogy továbbra is velünk tartasz! Emailben elküldtük a díjbekérőt, amit utalással vagy Barionon keresztül kártyás fizetéssel tudsz kiegyeníteni.',
  shopDisabledInfo: 'Ez a funkció csak akkor érhető el, ha van postázásra váró csomagod. Fizess elő további csomagokra, hogy újra rendelhess tőlünk játékokat is!',
  newPasswordTimeout: 'Sajnos ez a link már lejárt.',
  disabledProduct5Info: 'Egy csomag csak Foxpost kézbesítési móddal kérhető. Ha szeretnél Foxpostra váltani, írj egy emailt a választott automatával a jatszohazhoz@gmail.com címre!',
  disabledKeepItInfo: 'Ez a játék nem megvásárolható.',
  linkToLanding: 'Több információ a Játszó/Házhoz-ról',
  onlyFoxpostInfo: 'Jelenleg csak a Foxpost csomagautomatás kézbesítés érhető el.',
  disabledAddPackInfo: 'Jelenleg csak Foxpost csomagautomatás kézbesítéssel lehet új csomagokat rendelni. Ha szeretnél Foxpostra váltani, egyszerűen írj nekünk egy emailt a jatszohazhoz@gmail.com címre!',
  chooseFoxpostMachine: 'Mielőtt új csomagokat rendelsz, kérjük válassz Foxpost kézbesítési automatát!',
  addressTitleCoupon: 'Játszó/Házhoz kupon vásárlás',
  couponDescription: 'Az itt megadott email címre automatikusan kiküldésre kerül egy díjbekérőt, amit kártyás fizetéssel, vagy utalással is ki tudsz egyenlíteni. Amint megérkezett hozzánk a befizetés, 24 órán belül emailben küldjük a kupont. A kupont beváltó személynek a saját adataival kell majd regisztrálnia az itt megadott adatoktól függetlenül. A kupont a vásárlástól számított három hónapon belül lehet beváltani.',
  couponOrderDoneTitle: 'Köszönjük a rendelést!',
  couponOrderDoneText: 'Elküldtük emailben a díjbekérőt a fizetési információkkal. Ha nem érkezik meg néhány percen belül (figyelgesd a spam mappát is!), mindenképpen írj nekünk! Fizesd be a díjbekérőt és 24 órán belül emailben küldjük a kupont.'
}

export default lang
